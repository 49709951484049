<template>
<div>
  <v-form ref="resetPasswordForm" v-model="valid" lazy-validation autocomplete="off" aria-autocomplete="none">
    <v-container fluid>
      <v-row align="center" justify="center" class="text-center pa-0" no-gutters>
        <v-col class="text-left pb-10" cols="12">
          <h2 class="mb-1 pb-1 pl-0 font-weight-bolder">
            {{ $t('generic.lang_resetPassword') }}!
          </h2>
        </v-col>

        <v-col cols="12" lg="12" xl="12">
          <v-text-field :append-icon="visible ? 'lnr-eye' : 'lnr-eye'"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        :label="$t('generic.lang_neues_passwort')"
                        :type="visible ? 'text' : 'password'"
                        @click:append="visible = !visible"
                        @focus="showTouchKeyboard"
                        :rules="[rules.required]"
                        dense
                        autocomplete="off"
                        aria-autocomplete="none"
                        :hint="$t('generic.lang_atLeast5Characters')"
                        name="newPassword"
                        outlined v-model="newPassword" value=""/>

        </v-col>

        <v-col cols="12" lg="12" xl="12">
          <v-text-field :append-icon="visible ? 'lnr-eye' : 'lnr-eye'"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        :label="$t('generic.lang_neues_passwort_rep')"
                        :rules="[passwordMatch,rules.required]"
                        :type="visible ? 'text' : 'password'"
                        @click:append="visible = !visible"
                        @focus="showTouchKeyboard"
                        dense
                        autocomplete="off"
                        aria-autocomplete="none"
                        name="repeatPassword"
                        :hint="$t('generic.lang_atLeast5Characters')"
                        outlined v-model="newPasswordRepeat" value=""/>
        </v-col>
        <v-col cols="12" lg="12" xl="12">
          <password :strength-meter-only="true" class="ma-0" v-model="newPassword"/>
        </v-col>
        <v-col cols="12" lg="12" xl="12">
          <v-btn :disabled="loading || !valid" :loading="loading" block class="text-white mx-auto ma-auto"
                 color="#f84611" elevation="0"
                 tile @click="resetPassword()">
            {{ $t('generic.lang_reset') }}
          </v-btn>
        </v-col>
        <v-col cols="12" >
          <v-btn depressed text color="#f84611" block class="mx-auto" @click="showLogin">
            {{$t('generic.lang_login')}}
          </v-btn>
        </v-col>
      </v-row>
      <div class="text-center font-weight-light mt-3">
              <span class="text-muted font-size-md" style="font-size: 11px !important;">
                {{ $t('generic.lang_madeWithLoveBy') }} <span style="color: #f84611">3POS</span> {{ $t('generic.lang_posSystem') }} 
              </span>
      </div>

    </v-container>
  </v-form>
  <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
    <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                        :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                        :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
                        :layout="touchKeyboard.layout" :options="touchKeyboard.options"/>
  </div>
</div>
</template>

<script>
import Password from 'vue-password-strength-meter'

import {Events} from '../../plugins/events';
import sha512 from "js-sha512";
import mixin from "../../mixins/KeyboardMixIns";
import validation from "../../mixins/validation/validation";
import {mapState} from "vuex";

export default {
  name: "ResetPassword",
  components: {
    Password
  },
  mixins: [mixin,validation],
  data(){
    return{
      valid:true,
      visible:false,
      newPassword:null,
      newPasswordRepeat:null,
      loading:false,
      passwordMatch: (v) => (this.newPassword === v) || (this.$t('generic.lang_passwordYouEnteredDontMatch')),
    }
  },
  computed:{
    ...mapState([
      'api',
    ])
  },
  methods:{
    showLogin(){
      this.$store.commit('api/auth/deleteResetPasswordCreds');
      this.$emit('showLogin');
    },
    resetPassword(){
      if (!this.$refs.resetPasswordForm.validate()) {
        return;
      }
      this.loading = true;
      this.axios.post('update/resetPasswordFinal/', { //success
        resetUserId:this.api.auth['userId'],
        resetUserToken:this.api.auth['resetToken'],
        resetPassword:sha512(this.newPassword),
        resetPasswordRepeat:sha512(this.newPasswordRepeat),
      }).then((res) => {
        if(res.data.success){
          this.showLogin();
        }else{
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_errorOccurred')
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_errorOccurred')
        });
      }).finally(()=>{
        this.loading=false;
      })
    },
  }
}
</script>

<style scoped>

</style>