<template>
  <div class="pt-5">
    <transition mode="out-in" name="fade">
      <!-- Init -->
      <div v-if="showInitForm&&!showResetPasswordForm">
        <v-form ref="initForm" v-model="valid" lazy-validation @focus="removeTokenKeyPressEvent"
                @blur="addTokenKeyPressEvent">
          <v-container fluid>
            <v-row align="center" class="text-center pa-0" no-gutters>
              <v-col class="text-left pb-10" cols="12">
                <v-alert v-if="suspended" class="white--text" dismissible color="error">
                  {{ $t('generic.lang_suspendedMSG') }}
                </v-alert>
                <h2 class="mb-1 pb-1 pl-0 font-weight-bolder" style="color: #f84611">
                  {{ $t('generic.lang_welcome') }}!
                </h2>
                <span class="mt-6 font-weight-light font-size-md text-muted">{{
                    $t('generic.lang_connectWithYour3POS')
                  }} </span>

              </v-col>
              <v-col cols="12" lg="12" xl="12">
                <v-text-field
                    v-model="posID"
                    id="posID"
                    :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                    :disabled="loading"
                    :loading="loading"
                    :min="1"
                    :rules="posIDRules"
                    class="ma-auto rounded-0"
                    dense
                    label="3POS ID"
                    outlined
                    required
                    type="number"
                    @focus="inputFocus"
                    @keyup.enter="init()"
                    @blur="addTokenKeyPressEvent"
                />
              </v-col>

              <v-col cols="12" lg="12" xl="12">
                <v-text-field
                    id="posToken"
                    v-model="posToken"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :disabled="loading"
                    :loading="loading"
                    :rules="posTokenRules"
                    class="ma-auto rounded-0"
                    dense
                    label="3POS CODE"
                    outlined
                    required
                    type="text"
                    @focus="inputFocus"
                    @keyup.enter="init()"
                    @blur="addTokenKeyPressEvent"
                />
              </v-col>

              <v-col cols="12">
                <v-btn :disabled="loading || !valid || suspended" :loading="loading" block
                       class="text-white mx-auto ma-auto"
                       color="#f84611" elevation="0"
                       tile @click="init()">
                  {{ $t('generic.lang_login') }}
                </v-btn>
              </v-col>
            </v-row>

            <div class="text-center font-weight-light mt-3">
              <span class="text-muted font-size-md" style="font-size: 11px !important;">
                {{ $t('generic.lang_madeWithLoveBy') }} <span
                  style="color: #f84611">3POS</span> {{ $t('generic.lang_posSystem') }}
              </span>
            </div>

          </v-container>
        </v-form>
      </div>

      <div v-if="showLoginForm&&!showResetPasswordForm">
        <v-form ref="loginForm" v-model="valid2">
          <v-container>
            <v-row no-gutters style="">
              <v-col class="text-left pb-10" cols="12">

                <h2 class="mb-1 pb-1 pl-0 font-weight-bolder" style="color: #f84611">
                  {{ $t('generic.lang_welcome') }}
                </h2>
                <span class="mt-6 font-weight-light font-size-md text-muted">
                {{ $t('generic.lang_loginWithYourUser') }}
                </span>

              </v-col>
              <v-col cols="12" lg="12" xl="12">
                <v-text-field
                    id="userID"
                    v-model="userID"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :disabled="loading"
                    :label="$t('settings.lang_userid')"
                    :loading="loading"
                    class="ma-auto rounded-0"
                    dense
                    outlined
                    prepend-inner-icon="person"
                    type="number"
                    @focus="inputFocus"
                    @keyup.enter="login"
                    @blur="addTokenKeyPressEvent"
                />
              </v-col>

              <v-col cols="12" lg="12" xl="12">
                <v-text-field
                    id="userPassword"
                    v-model="userPassword"
                    :append-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :disabled="loading"
                    :error="userPasswordHasError"
                    :error-messages="userPasswordErrorMessages"
                    :label="$t('generic.lang_password')"
                    :loading="loading"
                    :type="visible ? 'text' : 'password'"
                    class="ma-auto rounded-0"
                    dense
                    outlined
                    prepend-inner-icon="lock"
                    @focus="inputFocus"
                    @keyup.enter="login"
                    @blur="addTokenKeyPressEvent"
                    @click:append="visible = !visible"
                />
              </v-col>

              <v-col cols="12">

                <v-btn :disabled="loading || userID === '' || userPassword.length < 3"
                       :loading="loading" block
                       class="text-white mx-auto ma-auto" color="#f84611"
                       elevation="0" tile
                       @click="login()">
                  {{ $t('generic.lang_login') }}
                </v-btn>
              </v-col>
              <v-col v-if="loginCount>0&&!$store.getters['permissions/checkHybridState']" cols="12">
                <ForgotPasswordDialogComponent/>
              </v-col>
            </v-row>

            <div class="text-center font-weight-light mt-3">
              <span class="text-muted font-size-md" style="font-size: 11px !important;">
                {{ $t('generic.lang_madeWithLoveBy') }} <span
                  style="color: #f84611">3POS</span> {{ $t('generic.lang_posSystem') }}
              </span>
            </div>
          </v-container>
        </v-form>
      </div>
      <div v-if="showResetPasswordForm && !loading">
        <ResetPassword @showLogin="showLogin"/>
      </div>

    </transition>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
                          :layout="touchKeyboard.layout" :options="touchKeyboard.options"/>
    </div>
  </div>
</template>

<script>

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import BarcodeKeyboard from "@/mixins/barcodeKeyboard";
import Password from 'vue-password-strength-meter'

import {Events} from '../../plugins/events';
import sha512 from "js-sha512";
import mixin from "../../mixins/KeyboardMixIns";
import validation from "../../mixins/validation/validation";
import {mapGetters, mapState} from "vuex";
import axios from "axios";
import ForgotPasswordDialogComponent from "./ForgotPasswordDialogComponent";
import ResetPassword from "./ResetPassword";


export default {
  name: "LoginForm",
  components: {
    ResetPassword,
    ForgotPasswordDialogComponent,
    'font-awesome-icon': FontAwesomeIcon,
    BarcodeKeyboard,
    Password
  },
  mixins: [mixin, validation],

  data() {
    return {
      visible: false,
      valid: true,
      valid2: true,
      loading: false,
      showInitForm: true,
      showLoginForm: false,
      showResetPasswordForm: false,
      bs: "",
      bsid: "",
      posCode: "",
      apiAuthToken: "",
      kellnerSchloss: "",
      qrString: "",
      tokenKellnerClearID: null,
      newPassword: null,
      newPasswordRepeat: null,
      posID: "",
      posIDRules: [
        v => !!v || this.$t('generic.lang_requiredField'),
      ],
      posToken: "",
      posTokenRules: [
        v => !!v || this.$t('generic.lang_requiredField'),
      ],
      userID: "",
      userPassword: "",
      userPasswordHasError: false,
      userPasswordErrorMessages: '',
      suspended: false,
      loginCount: 0,
    }
  },

  watch: {
    posToken: function () {
      this.posToken = this.posToken.toUpperCase();
    },
    userPassword: function () {
      this.userPasswordHasError = false;
      this.userPasswordErrorMessages = "";
    }
  },

  computed: {
    ...mapState([
      'api',
      'cashierIDs',
      'user'
    ]),
    ...mapGetters({
      initDataLoaded: 'app/initDataLoaded'
    }),
    offlineMessage() {
      return process.env.VUE_APP_HYBRID !== 'true' ? this.$t('generic.lang_checkInternetConnection') : this.$t('generic.lang_checkNetworkConnection')
    }
  },

  mounted() {
    //CHECK IF BS ID / BS ACCESS STRING IS SET
    let accessString = this.api.auth.accessString;
    let posID = this.api.auth.posID;

    //Check if we can skip init form
    if (posID > 0 && accessString) {
      this.bsid = accessString;
      this.bs = posID;

      //WE DO NOT NEED TO SET AXIOS HEADERS -> THEY ARE ALREADY SET IN App.vue
      this.init2();
    }

    // reseting the scale settings;
    this.$store.dispatch("scale/toggleScale", false);
    this.$store.dispatch("scale/setScaleSettings", null);


    //this.tokenKeyInterval();

    if (this.api.auth.hasOwnProperty('userId') && this.api.auth.hasOwnProperty('resetToken')) {
      this.showResetPasswordForm = true;
    }

    window.addEventListener("keypress", this.handleScanner);
  },
  methods: {
    removeTokenKeyPressEvent() {
      this.kellnerSchloss = "";
      window.removeEventListener('keypress', this.handleScanner);
    },
    addTokenKeyPressEvent() {
      this.kellnerSchloss = "";
      window.addEventListener('keypress', this.handleScanner);
    },
    inputFocus(event) {
      this.removeTokenKeyPressEvent();
      this.showTouchKeyboard(event)
    },
    showLogin() {
      this.showResetPasswordForm = false;
      this.showLoginForm = true;
    },
    //GET KELLNERSCHLOSS
    handleScanner(e) {
      //CHECK IF CLERK TOKEN KEY IS ACTIVE IN MODULES
      //if (this.$store.getters['permissions/checkModule'](26)) {
      if(this.showInitForm){
        this.qrString += e.which !== 13 ? String.fromCharCode(e.keyCode) : "";
        if (e.which === 13) {
            e.preventDefault();
            [this.posID, this.posToken] = this.qrString.split("|") 
            setTimeout(this.init, 150);
            this.qrString = "";
        }
      }else {
        this.kellnerSchloss += String.fromCharCode(e.keyCode);
        if (e.which === 13) {
          if (this.showLoginForm) {
            e.preventDefault();
            this.login();
          }
        }
      }
      //}
    },
    //RESET KELLNERSCHLOSS
    /* tokenKeyInterval() {
         const self = this;
         this.tokenKellnerClearID = setInterval(function () {
             self.kellnerSchloss = ""
         }, 500);
     },*/
    init() {
      if (!this.$refs.initForm.validate()) {
        return;
      }

      if (this.posID.length < 1 || this.posToken.length < 3) {
        return;
      }

      this.loading = true;
      let f = new FormData();

      this.axios.post('get/init/', { //success
        posID: this.posID,
        posToken: this.posToken
      }).then((res) => {
        this.loading = false;

        if (res.data.success) {
          this.bsid = res.data.bsid;
          this.bs = res.data.bs;
          this.posCode = res.data.posCode;
          //DISPATCH TO STORE
          this.$store.dispatch("api/auth/init", {
            accessString: res.data.bsid,
            posID: res.data.bs,
            posCode: res.data.posCode,
            cashierID: 1,
            deviceID: 1
          }).then(() => {
            //SET AXIOS HEADERS
            this.axios.defaults.headers.common['3POSBS'] = this.bs;
            this.axios.defaults.headers.common['3POSBSID'] = this.bsid;

            this.init2();
          });
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_errorOccurred')
        });

        this.loading = false;
      });
    },
    init2() {
      this.loading = true;

      this.axios.post('get/index/', {posCode: this.posCode}).then((res) => {


        if (res.data.success) {
          this.apiAuthToken = res.data.data.apiAuth.token;

          //SET AXIOS HEADERS
          this.axios.defaults.headers.common['3POSAPITOKEN'] = this.apiAuthToken;

          //UPDATE API AUTH TOKEN
          this.$store.dispatch("api/auth/updateToken", this.apiAuthToken);

          //AVAILABLE CASHIER IDS
          this.$store.dispatch("cashierIDs/update", res.data.data.cashierIDs, res.data.data.cashiers);
          this.$store.dispatch("cashierIDs/setCashiers", res.data.data.cashiers);

          //GET FIRST CASHIER ID
          let firstCashierID = res.data.data.cashiers[0];

          //NOW SET THE FIRST CASHIER ID
          let cashierIDToSet = firstCashierID.id;

          // CHECK IF CASHIER ID ALREADY SET AND DOES NOT EXISTS
          if (this.api.auth.cashierID > 0)
            if (res.data.data.cashierIDs.includes(this.api.auth.cashierID))
              cashierIDToSet = this.api.auth.cashierID;

          this.$store.commit("api/auth/setCashierID", cashierIDToSet);
          this.$store.commit("api/auth/setHybridEnabled", res.data.data.hybridEnabled);

          //SET START PAGE LOGO:
          if (res.data.data.startPage.length > 5) {
            this.imageUrl = 'https://img.3pos.de/unsafe/0x1080/filters:quality(80)/billsys_' + parseInt(this.api.auth.posID) + '/original/' + res.data.data.startPage;
            this.$emit('eventname', this.imageUrl)
          }


          //UPDATE API AUTH TOKEN EXPIRATION
          this.$store.dispatch("api/auth/updateTokenExpiration", res.data.data.apiAuth.expiration);

          this.showInitForm = false;
          this.showLoginForm = true;
          this.suspended = false;
          if (res.data.data.msg === "PAYMENTDUE") {
            this.$swal({
              title: this.$t('generic.lang_warning'),
              text: this.$t("generic.lang_paymentDue").replaceAll('{{till}}', res.data.data.till),
              icon: "warning",
              showLoaderOnConfirm: true,
              confirmButtonText: this.$t("generic.lang_confirm"),
              preConfirm: () => {

              },
              allowOutsideClick: () => !this.$swal.isLoading,
            });
          }
         if(res.data.data.hybridEnabled && res.data.data.hybridIp ){
            if(res.data.data.hybridIp && this.rules.ipAddress(res.data.data.hybridIp) === true){
              try {
                let hybridIp = res.data.data.hybridIp;
                let protocol = "http";
                const hybridURL = new URL(`${protocol}://${hybridIp}`);
                if(this.$store.getters["api/auth/hybridAddress"]!== `${protocol}://${hybridIp}` && (process.env["VUE_APP_ELECTRON"] === "true" || process.env["VUE_APP_CAPACITOR"] === "true")){
                  this.$store.commit("api/auth/setHybridAddress", hybridURL.origin);
                  axios.defaults.baseURL = this.$store.getters['api/auth/hybridAPIURL'];
                  this.$socket.io.uri = this.$store.getters['api/auth/hybridSocketURL'];
                  this.init2();
                }
              }catch (err) {
                Events.$emit("showSnackbar", {
                  color: "error",
                  message: this.$t('generic.lang_errorOccurred') + " " + err
                });
              }
            }else{
              this.$store.commit("api/auth/setHybridAddress", null);
            }
          }else{
            this.$store.commit("api/auth/setHybridAddress", null);
          }
        } else if (!res.data.success && res.data.msg === "SUSPENDED") {

          this.$swal({
            title: this.$t('generic.lang_warning'),
            text: this.$t("generic.lang_suspendedMSG"),
            icon: "warning",
            showLoaderOnConfirm: true,
            confirmButtonText: this.$t("generic.lang_confirm"),
            preConfirm: () => {

            },
            allowOutsideClick: () => !this.$swal.isLoading,
          });
          this.suspended = true;
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_errorOccurred') + " " + res.data.msg
          });
        }
      }).catch((err) => {
        if (err.message.includes("Network Error")) {
          this.$swal({
            title: this.$t('generic.lang_noConnection'),
            text: this.offlineMessage,
            icon: "warning",
            showLoaderOnConfirm: true,
            confirmButtonText: 'Ok',
            allowOutsideClick: () => !this.$swal.isLoading,
          });
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_errorOccurred')
          });
        }

      }).finally(() => {
        this.loading = false;
      })
    },
    selectCashierID(cashierID) {
      if(!this.initDataLoaded || this.api.auth.cashierID !== cashierID){
        this.$store.dispatch("api/auth/setCashierID", cashierID);
        this.$store.dispatch("items/resetState", []);
        this.$store.dispatch("itemgroups/resetState", []);
        this.$store.dispatch("app/updateInitDataLoadedState", false);
      }
    },
    login() {
      this.loading = true;
      const self = this;
      let postData = {
        cashier_id: this.api.auth.cashierID,
      };
      if (this.kellnerSchloss && this.kellnerSchloss.length > 1) {
        postData.tokenID = this.kellnerSchloss;
      } else {
        postData.user_id = this.userID;
        postData.password = sha512(this.userPassword);
      }
      this.axios.post('get/login/', postData).then((res) => {
        this.loading = false;
        if (res.data.status === "LOGEDIN") {
          self.kellnerSchloss = "";


          this.userID = res.data.userData.userID;

          //Permissions
          this.$store.dispatch("permissions/update", {
            modules: res.data.modules,
            permissions: res.data.permissions,
            hybrid: res.data.usesHybrid
          });

          //Fiscal state
          this.$store.dispatch("app/setFiscalState", res.data.fiscalState);
          this.$store.commit("app/setUseAPIPos", res.data.itemCount >= 4000, {
            root: true,
          });

          //Printer Template
          this.$store.dispatch("printer/updateTemplates", res.data.printerTemplates);

          //Printer Template Storno
          this.$store.dispatch("printer/updateStornoTemplates", res.data.printerStornoTemplates);

          //UPDATE EC TERMINAL TYPE:
          this.$store.dispatch("payments/updateEcPaymentType", res.data.ecTerminalType);

          //Check scale settings
          if (res.data.hasOwnProperty('scaleSettings') && !Array.isArray(res.data.scaleSettings)) {
            this.$store.dispatch("scale/toggleScale", true);
            this.$store.dispatch("scale/setScaleSettings", {
              host: res.data.scaleSettings.ip,
              port: res.data.scaleSettings.port,
            });
          }

          //assigning the returned cashier id
          this.selectCashierID(res.data.usesCashierId);

          this.openElectronCustomerDisplay();
          //SET LOGEDIN
          this.$store.dispatch("user/login", {
            loggedIn: true,
            userID: parseInt(this.userID),
            name: res.data.userData.name,
            last_name: res.data.userData.last_name,
            avatarURI: res.data.userData.avatarURI,
            tokenColor: res.data.userData.tokenColor,
            lastAPITransactionLog: {
              row: res.data.dbLastSyncRow,
              timestamp: res.data.dbLastUpdate
            },
            vm: this
          }).then(() => {
            //CHECKING IF HYBRID ENABLED IN CLOUD BEFORE REDIRECTING
            this.axios.post('get/dbSync/', {
              lastSync: res.data.dbLastUpdate,
              lastRow: res.data.dbLastSyncRow,
            }).then((res) => {
              if (res.data.hybridStatus && res.data.hybridStatus.status && process.env.VUE_APP_HYBRID == "false") {
                //calc current time and last hybrid sync diff
                let diff = (Math.round(new Date().getTime()) - (Number(res.data.hybridStatus.lastHybridContact) * 1000)) / 60000;
                //check if the hybrid server is offline 10 minutes ago
                if (diff >= 10) {
                  this.$router.replace({
                    name: 'dashboard',
                  });
                }
              }
            })

            //
            //REDIRECT
            if (res.data.userStartingPoint && res.data.userStartingPoint === 'pos.gastro.tables' && this.$vuetify.breakpoint.smAndDown) {
              this.$router.replace({
                name: 'pos.gastro',
              });
            } else {
              this.$router.replace({
                name: res.data.userStartingPoint
              });
            }
          });
        } else if (res.data.status === "FATAL" || res.data.status === "API TOKEN VERIFY ERROR") {
          //FATAL ERROR: REDIRECT TO INIT PAGE
          this.resetLoginForm();
          self.kellnerSchloss = "";
        } else if (res.data.status === "LOCK" || res.data.status === "API_ERROR") {
          //API TOKEN ERROR TRY TO GET NEW ONE + FORCE LOGIN
          this.init2();
          self.kellnerSchloss = "";
        } else if (res.data.status === "WRONGPW") {
          self.kellnerSchloss = "";
          this.userPasswordHasError = true;
          this.userPasswordErrorMessages = this.$t('generic.lang_error_wromg_pw');
          this.loginCount += 1;
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_error_wromg_pw')
          });
        } else if (res.data.status === "LICENSE_EXPIRED") {
          self.kellnerSchloss = "";
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_noValidCashierLicenseFound')
          });
        } else if (res.data.status === "DUPUSER") {
          /*DUPLICATE USER*/
          this.$swal({
            title: this.$t('generic.lang_dupUserLoginTitle'),
            text: this.$t('generic.lang_dupUserLoginText'),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: this.$t('generic.lang_cancel'),
            confirmButtonText: this.$t('settings.lang_LogOutUser'),
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.loading = true;
              postData.deleteUserSessions = "TRUE";
              this.axios.post('get/login/', postData).then((res) => {
                this.loading = false;

                if (res.data.status === "LOGEDIN") {
                  self.kellnerSchloss = "";
                  //Permissions
                  this.$store.dispatch("permissions/update", {
                    modules: res.data.modules,
                    permissions: res.data.permissions,
                    hybrid: res.data.usesHybrid
                  });

                  //Fiscal state
                  this.$store.dispatch("app/setFiscalState", res.data.fiscalState);
                  this.$store.commit("app/setUseAPIPos", res.data.itemCount >= 4000, {
                    root: true,
                  });
                  //Printer Template
                  this.$store.dispatch("printer/updateTemplates", res.data.printerTemplates);

                  //Printer Template Storno
                  this.$store.dispatch("printer/updateStornoTemplates", res.data.printerStornoTemplates);

                  //UPDATE EC TERMINAL TYPE:
                  this.$store.dispatch("payments/updateEcPaymentType", res.data.ecTerminalType);

                  //Check scale settings
                  if (res.data.hasOwnProperty('scaleSettings') && !Array.isArray(res.data.scaleSettings)) {
                    this.$store.dispatch("scale/toggleScale", true);
                    this.$store.dispatch("scale/setScaleSettings", {
                      host: res.data.scaleSettings.ip,
                      port: res.data.scaleSettings.port,
                    });
                  }

                  //assigning the returned cashier id
                  this.selectCashierID(res.data.usesCashierId);

                  this.openElectronCustomerDisplay();

                  //SET LOGEDIN
                  this.$store.dispatch("user/login", {
                    loggedIn: true,
                    userID: parseInt(res.data.userData.userID),
                    name: res.data.userData.name,
                    last_name: res.data.userData.last_name,
                    tokenColor: res.data.userData.tokenColor,
                    lastAPITransactionLog: {
                      row: res.data.dbLastSyncRow,
                      timestamp: res.data.dbLastUpdate
                    },
                    vm: this
                  }).then(() => {
                    //REDIRECT
                    this.$router.replace({
                      name: 'dashboard'
                    });
                  });
                } else if (res.data.status === "FATAL" || res.data.status === "API TOKEN VERIFY ERROR") {
                  //FATAL ERROR: REDIRECT TO INIT PAGE
                  this.resetLoginForm();
                } else if (res.data.status === "LOCK") {
                  this.init2();
                }
              }).catch(() => {
                Events.$emit("showSnackbar", {
                  color: "error",
                  message: this.$t('generic.lang_errorOccurred')
                });

                this.loading = false;
              });
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          });
        }
      }).catch((err) => {
        if (err.message.includes("Network Error")) {
          this.$swal({
            title: this.$t('generic.lang_noConnection'),
            text: this.offlineMessage,
            icon: "warning",
            showLoaderOnConfirm: true,
            confirmButtonText: 'Ok',
            allowOutsideClick: () => !this.$swal.isLoading,
          });
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_errorOccurred')
          });
        }

        this.loading = false;
      })

      this.kellnerSchloss = "";
    },

    resetLoginForm() {
      this.$store.dispatch("user/clearData").then(() => {
        this.showInitForm = true;
        this.showLoginForm = true;
        this.userID = "";
        this.userPassword = "";
        this.bs = "";
        this.bsid = "";
        this.apiAuthToken = "";
      });
    },
    openElectronCustomerDisplay() {
      if (process.env.VUE_APP_ELECTRON === "true") {
        try {
          const {ipcRenderer} = require("electron");
          // opens a customer display window in electron app case
          let currentCashier = this.cashierIDs.cashiers.find(cashier => cashier.id === this.api.auth.cashierID);

          if (currentCashier && currentCashier.customerDisplayUrl) {
            let customerDisplayUrl = currentCashier.customerDisplayUrl;
            if(this.$store.getters['api/auth/hybridEnabled'] && this.$store.getters['api/auth/hybridAddress']){
              customerDisplayUrl = currentCashier.customerDisplayUrl.replace("https://kundendisplay.3pos.de", this.$store.getters["api/auth/hybridCustomerDisplayURL"])
            }
            ipcRenderer.send("open-customer-display", customerDisplayUrl)
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  },
  destroyed() {
    window.removeEventListener('keypress', this.handleScanner);
    //clearInterval(this.tokenKellnerClearID);
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

</style>
